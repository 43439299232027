<template>
  <Wrapper>
    <Icon
      src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/Matchday_Logo_Final_Transparent_2x_lhthq6.png"
    />
    <Display>ขออภัย เราไม่พบหน้าที่คุณกำลังมองหา</Display>
    <router-link to="/" class="btn btn-primary btn-lg"
      >กลับสู่หน้าหลัก</router-link
    >
  </Wrapper>
</template>

<script>
import styled from "vue3-styled-components";
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  * {
    margin: 1rem 0;
  }
`;
const Display = styled.span`
  color: #2d2d2d;
  font-size: 2.5rem;
`;
const Icon = styled.img`
  width: 6rem;
  height: 6rem;
  object-fit: cover;
`;
export default {
  name: "NotFound",
  components: { Wrapper, Display, Icon }
};
</script>
